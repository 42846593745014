import {useState} from "react";
import {
    Box,
    IconButton,
    InputBase,
    Typography,
    Select,
    MenuItem,
    FormControl,
    useTheme,
    useMediaQuery, Button, Divider
} from "@mui/material";
import "./index.css";

import {
    DarkMode,
    LightMode,
    Menu,
    Close, Message
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {setLan, setMode} from "../../state";
import FlexBetween from "../../components/FlexBetween";
import {useNavigate} from "react-router-dom";
import patini from "../../assets/patini.png";
import patates from "../../assets/patates.png";
import stafili from "../../assets/stafili.png";
import scr from "../homepage"

const Navbar = () => {
    const [isMobileMenuToggled, setIsMobileMenuToggled]= useState(false);
    const Lan = useSelector((state) => state.Lan);
    const [isLan, setIsLan] = useState(Lan);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isNonMobileScreens = useMediaQuery("(min-width: 800px)");
    const isNonSmolScreen = useMediaQuery("(min-height: 800px)");

    const theme = useTheme();
    const neutralLight = theme.palette.neutral.light;
    const dark = theme.palette.neutral.dark;
    const background = theme.palette.background.default;
    const primaryLight = theme.palette.primary.light;
    const primaryMain = theme.palette.primary.main;
    const alt = theme.palette.background.alt;
    const pinkBack = theme.palette.background.alt2;
    const purple = theme.palette.primary.purple;
    const el = document.getElementById("footer");


    const homeName = Lan === "gr" ? "ΑΡΧΙΚΗ" : "PEZODROMIO";
    const ekdotikosName = Lan === "gr" ? "ΕΚΔΟΤΙΚΗ " : "LA EDITORIAL";
    const commName = Lan === "gr" ? "ΕΠΙΚΟΙΝΩΝΙΑ" : "COMUNICACIÓN";


    const goToEkd=() => {
        document.getElementById("ekd").scrollIntoView();
    }

    const goToEp=() => {
        document.getElementById("ep").scrollIntoView();
    }

    return(
        <div>
        <FlexBetween gap="0.5rem" alignItems="center" maxHeight="15vh" backgroundColor={pinkBack} >
            <FlexBetween></FlexBetween>
            {isNonMobileScreens && isNonSmolScreen && (
            <FlexBetween>

                <img  src={patates} style={{visibility: "hidden"}} width="82rem" height="128rem"></img>

            <img src={patates} width="82rem" height="128rem"></img>

                <h1>ΠΕΖΟΔΡΟΜΙΟ</h1>


                <img  src={stafili} width="82rem" height="128rem"></img>

                <img src={patini} width="82rem" height="128rem"></img>
            </FlexBetween>
                )}
            {(!isNonMobileScreens || !isNonSmolScreen) &&(
                <FlexBetween>


                    <img src={patates} width="60rem" height="90rem"></img>
                    <h1>
                        ΠΕΖΟΔΡΟΜΙΟ
                    </h1>
                    <img src={stafili} width="60rem" height="90rem"></img>

                    <img src={patini} width="60rem" height="90rem"></img>
                </FlexBetween>

            )}
            <FlexBetween></FlexBetween>
        </FlexBetween>
        <FlexBetween maxHeight="5rem" padding="1rem 6%" alignItems="center" backgroundColor={alt} border="2px solid" sx={{ borderColor: purple, }} >
            <FlexBetween></FlexBetween>
            {isNonMobileScreens && (
                <FlexBetween  justify-content="column" gap="2rem">
                    <FlexBetween>


                            <h2 onClick={() => navigate("/")}>
                            {homeName}
                            </h2>


                        <Typography sx={{visibility: "hidden"}}>keno</Typography>


                            <h2 onClick={goToEkd}>
                            {ekdotikosName}
                            </h2>


                        <Typography sx={{visibility: "hidden"}}>keno</Typography>


                            <h2 onClick={goToEp}>
                            {commName}
                            </h2>


                    </FlexBetween>

                    <Button >

                            <h2 onClick={() => dispatch(setLan()) }>
                        Ελλ/Es
                            </h2>

                    </Button>

                    <IconButton
                        sx={{
                            "&:hover": {
                                fontSize: "2rem",
                                color: primaryMain,
                                cursor: "pointer"
                            },
                        }}
                        onClick={() => dispatch(setMode())}
                    >
                        {theme.palette.mode === "dark" ? (
                            <DarkMode sx={{fontSize: "25px"}} />
                        ) : (
                            <LightMode sx={{color: "dark", fontSize: "25px"}} />
                        )}
                    </IconButton>

                </FlexBetween>


                )}
            {/* mobile nav */}

            {!isNonMobileScreens && isMobileMenuToggled&& (
                <Box
                    position="fixed"
                    right="0"
                    bottom="0"
                    height="100%"
                    zIndex="10"
                    maxWidth="500px"
                    minwidth="300px"
                    backgroundColor={background}
                >
                    <Box display="flex" justifyContent="flex-end" p="1rem">
                        <IconButton onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}>
                        <Close/>
                        </IconButton>
                    </Box>
                    {/* Menu Items */}

                            <FlexBetween display="flex" flexDirection="column" gap="2.5rem">
                                <FlexBetween>

                                        <h3 onClick={() => navigate("/")}>
                                        {homeName}
                                        </h3>

                                </FlexBetween>

                                    <h3 onClick={goToEkd}>
                                    {ekdotikosName}
                                    </h3>



                                    <h3 onClick={goToEp}>
                                    {commName}
                                    </h3>



                                    <h3 onClick={() => dispatch(setLan())}>
                                    Ελλ/Es
                                    </h3>

                                <IconButton
                                    sx={{
                                        "&:hover": {
                                            color: primaryMain,
                                            cursor: "pointer"
                                        },
                                    }}
                                    onClick={() => dispatch(setMode())}
                                >
                                    {theme.palette.mode === "dark" ? (
                                        <DarkMode sx={{fontSize: "25px"}} />
                                    ) : (
                                        <LightMode sx={{color: "dark", fontSize: "25px"}} />
                                    )}
                                </IconButton>

                            </FlexBetween>
                </Box>
            )}
            <FlexBetween></FlexBetween>
            {!isNonMobileScreens && (
                <FlexBetween>
                    <FlexBetween></FlexBetween>
                    <FlexBetween></FlexBetween>
                    <IconButton
                        display="flex"
                        sx={{
                            "&:hover": {
                                color: primaryMain,
                                cursor: "pointer"
                            },
                        }}
                        onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
                    >
                        <Menu/>
                    </IconButton>
                </FlexBetween>
            )}
        </FlexBetween>
        </div>
    )

}

export default Navbar;