// color design tokens export

export const colorTokens = {
    grey: {
        0: "#FFFFFF",
        10: "#ffffff",
        50: "#ffffff",
        100: "#ffffff",
        200: "#ffffff",
        300: "#A3A3A3",
        400: "#858585",
        500: "#0c0c0c",
        600: "#4D4D4D",
        700: "#333333",
        800: "#1A1A1A",
        900: "#0A0A0A",
        1000: "#000000",
    },
    primary: {
        50: "#ffc3e2",
        100: "#F6E7D8",
        200: "#ff7bb2",
        300: "#f557ab",
        400: "#ff3277",
        500: "#C65D7B",
        600: "#bc0096",
        700: "#7d0030",
        800: "#874356",
        900: "#19000a",
    },
};

// mui theme settings
export const themeSettings = (mode) => {
    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
                ? {
                    // palette values for dark mode
                    primary: {
                        dark: colorTokens.primary[200],
                        main: colorTokens.primary[500],
                        light: colorTokens.primary[800],
                        purple: colorTokens.primary[800],
                    },
                    neutral: {
                        dark: colorTokens.grey[100],
                        main: colorTokens.grey[200],
                        mediumMain: colorTokens.grey[300],
                        medium: colorTokens.grey[400],
                        light: colorTokens.grey[700],
                    },
                    background: {
                        default: colorTokens.grey[900],
                        alt: colorTokens.grey[800],
                        alt2: colorTokens.primary[100],
                    },
                    pinkBackground:{
                        default: colorTokens.primary[100],
                    },
                }
                : {
                    // palette values for light mode
                    primary: {
                        dark: colorTokens.primary[700],
                        main: colorTokens.primary[500],
                        light: colorTokens.primary[50],
                        purple: colorTokens.primary[800],
                    },
                    neutral: {
                        dark: colorTokens.grey[700],
                        main: colorTokens.grey[500],
                        mediumMain: colorTokens.grey[400],
                        medium: colorTokens.grey[300],
                        light: colorTokens.grey[50],
                    },
                    background: {
                        default: colorTokens.grey[10],
                        alt: colorTokens.grey[0],
                        alt2: colorTokens.primary[100],
                    },
                    pinkBackground:{
                        default: colorTokens.primary[100],
                    },
                }),
        },
        typography: {
            fontFamily: ["Sofia Sans Extra Condensed", "Roboto Condensed"].join(","),
            fontSize: 16,

        },


    };
};