import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    mode: "light",
    token: null,
    user: null,
    books: [{}],
    posts: [{}],
    Lan: "",
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers:{
        setLan: (state) => {
          state.Lan = state.Lan === "gr" ? "es" : "gr";
        },
        setMode: (state) => {
            state.mode = state.mode === "light" ? "dark" : "light";
        },

        setBooks: (state, action) => {
            state.books = action.payload.books;
        },
        setBook: (state, action) => {
            const updatedBooks = state.books.map((book)=>{
                if (book._id === action.payload.book._id) return action.payload.book;
                return book;
            });
            state.books= updatedBooks;
        },
        setPosts: (state, action) => {
            state.posts = action.payload.posts;
        },
        setPost: (state, action) => {
            const updatedPosts = state.posts.map((post)=>{
                if (post._id === action.payload.post._id) return action.payload.post;
                return post;
            });
            state.posts= updatedPosts;
        },
        setLogin: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        setLogout: (state) => {
            state.user = null;
            state.token = null;
        },

    }
})

export const { setLogin,setMode,setLogout, setLan, setBooks, setBook, setPosts, setPost} = authSlice.actions;
export default authSlice.reducer;