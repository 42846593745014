import {useState} from "react";
import {
    Box,
    IconButton,
    InputBase,
    Typography,
    Select,
    MenuItem,
    FormControl,
    useTheme,
    useMediaQuery, Button, Divider, TextField
} from "@mui/material";
import "./Footer.css";

import {
    DarkMode,
    LightMode,
    Menu,
    Close, Message
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import FlexBetween from "../../components/FlexBetween";
import {useNavigate} from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import kialia from "../../assets/Escaner.jpg";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';


const Footer = () => {
    const [isMobileMenuToggled, setIsMobileMenuToggled]= useState(false);
    const Lan = useSelector((state) => state.Lan);
    const [isLan, setIsLan] = useState(Lan);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isNonMobileScreens = useMediaQuery("(min-width: 1030px)");

    const theme = useTheme();
    const neutralLight = theme.palette.neutral.light;
    const dark = theme.palette.neutral.dark;
    const background = theme.palette.background.default;
    const primaryLight = theme.palette.primary.light;
    const primaryMain = theme.palette.primary.main;
    const alt = theme.palette.background.alt;
    const pinkBack = theme.palette.background.alt2;
    const purple = theme.palette.primary.purple;

    const skitsa = Lan === "gr" ? "Σκίτσα του Alessander Ochoa Argazki ©" : "Dibujos de Alessander Ochoa Argazki ©";
    const commName = Lan === "gr" ? " ΕΠΙΚΟΙΝΩΝΙΑ:" : " CONTACTO:";

    const epikoinonia1 = (Lan==="gr") ?
        " kyriaki@sto-pezodromio.com"
        :
        " kyriaki@sto-pezodromio.com" ;

    const epikoinonia2 = (Lan==="gr") ?
        " antonis@sto-pezodromio.com" :
        " antonis@sto-pezodromio.com";

    const news = (Lan === "gr") ? "Για να λαμβάνετε ενημερώσεις από το ΠΕΖΟΔΡΟΜΙΟ, εγγραφείτε εδώ: " : "Para recibir información subscríbete a nuestro boletín:" ;
    const sub = (Lan === "gr") ? "Διεύθυνση Ηλεκτρονικού Ταχυδρομίου" : "Correo Electrónico";
    const suc = (Lan === "gr") ? "Εγγραφίκατε επιτυχώς" : "éxito";

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();


        emailjs.sendForm('service_hvwqkfl', 'template_g3froiq', form.current, 'bHq5BM4DIGH4Uy6z1')
            .then((result) => {
                console.log(result.text);
                alert(suc);
            }, (error) => {
                console.log(error.text);
            });
        document.getElementById("emid").value="";
    };

    return(
        <div id="footer">
            {isNonMobileScreens&&(
            <FlexBetween  color="white" alignItems="center" width="100%"  minHeight="25rem" justifyContent="column" backgroundColor={purple} >
                <Box marginBottom="3rem" marginRight="10rem" >

                <Typography whiteSpace="nowrap" fontSize="clamp(0.5rem, 1.5rem, 1.9rem)" marginLeft="1rem">
                    <h6>
                    {commName}
                    </h6>


                    {epikoinonia1}
                    <br></br>

                    {epikoinonia2}
                </Typography>

                </Box>

                <Box  textAlign="center" marginBottom="5rem" marginRight="5rem" name="letters" class="letters" className="letters"  >

                    {news}
                    <br/>
                    <FormControl >
                        <form ref={form} onSubmit={sendEmail}>
                        <TextField
                            label={sub}
                            id="emid"
                            name="user_email"
                            type="email"
                            sx={{ width: "30vw", fontSize: "5rem", }}
                        />
                            <br/>

                            <input type="submit" className="subut" name="subut" value={(Lan==="gr") ? "Εγγραφη" : "Suscripción"}/>

                        </form>
                    </FormControl>


                </Box>


                <WidgetWrapper  sx={{opacity: 0.5, borderRadius: "5%"}} >
                    <Box >


                <img src={kialia} width="280vw"  height="320vh" ></img>
                        <Typography  sx={{fontSize:"1.3rem", color: "white"}} >
                            {skitsa}
                        </Typography>
                    </Box>
                </WidgetWrapper>
            </FlexBetween>
                )}
            {!isNonMobileScreens&& (
                <Box gap="3rem" color="white" textAlign="center" width="100%" sx={{ flexDirection: "row" }} backgroundColor={purple} >
                    <Box marginTop="5rem">
                        <Typography width="25rem" name="letters" class="letters" className="letters"   marginLeft="1rem">
                            <h6>
                            {commName}
                            </h6>
                            <br></br>
                            <br></br>

                            {epikoinonia1}
                            <br></br>

                            {epikoinonia2}
                        </Typography>
                    </Box>



                <Box justifyContent="rows" textAlign="center">
                    <Typography sx={{ marginTop: "2rem"}} width="100%" name="letters" class="letters" justifyContent="center" className="letters"  height="15rem">

                        {news}
                            <br/>
                        <FormControl  maxHeight="20rem" >
                            <form ref={form} onSubmit={sendEmail}>
                                <Box justifyContent="center">
                                <TextField
                                    label={sub}
                                    name="user_email"
                                    id="emid"
                                    type="email"
                                    sx={{ width: "50vw", fontSize: "5rem", }}
                                />
                                    <br/>
                                <input type="submit" className="subut" name="subut" value={(Lan==="gr") ? "Εγγραφη" : "Suscripción"}/>
                                </Box>
                            </form>
                        </FormControl>
                    </Typography>
                </Box>


                    <WidgetWrapper sx={{opacity: 0.5, borderRadius: "5%"}} >
                        <Box textAlign="center">



                            <img  src={kialia} width="210rem"  height="230rem"></img>
                            <Typography  sx={{fontSize:"1.3rem", color: "white"}} m="2rem 0" >
                                {skitsa}
                            </Typography>

                        </Box>
                    </WidgetWrapper>
                </Box>

            )}

        </div>
    )

}

export default Footer;