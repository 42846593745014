import Navbar from "../navbar/index.jsx"
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useSelector} from "react-redux";

import FlexBetween from "../../components/FlexBetween";
import patates from "../../assets/patates.png"
import patates2 from "../../assets/patates2.png";
import patates3 from "../../assets/patates3.png";
import pig from "../../assets/pig5.jpg"
import "index.css";
import WidgetWrapper from "../../components/WidgetWrapper";
import kialia from "../../assets/Escaner.jpg"
import Footer from "../footer/Footer.jsx";

import stafili from "../../assets/stafili.png";
const HomePage = () => {
    const theme = useTheme();
    const mode = useSelector((state) => state.mode);
    const primary = theme.palette.primary.main;
    const isNonMobileScreens = useMediaQuery("(min-width:800px)");
    const Lan = useSelector((state) => state.Lan);
    const introTitle= "ΠΕΖΟΔΡΟΜΙΟ";
    const intro = (Lan==="gr") ? "Ένα αστικό σύμβολο, ο αναλογικός χώρος που διευκολύνει την αδιάκοπη κυκλοφορία στους ανθρώπους, κατοίκους των\n" +
        "πόλεων και φορείς ιδεών, σε μια εποχή που επιβάλλει να επανεξετάσουμε την κυριαρχία και την ορθότητα της ανθρώπινης νοημοσύνης." :
        "La palabra ΠΕΖΟΔΡΟΜΙΟ en griego significa la acera, un símbolo urbano, un espacio físico que facilita la incesante circulación de las personas, portadoras de ideas, en una época que impone revisar la soberanía y la rectitud de la inteligencia humana.\n" +
        "habitantes en las ciudades, portadores de ideas, en una época que impone revisar la soberanía y la rectitud de la inteligencia humana.";
    const ektitle= (Lan === "gr") ? " ΕΚΔΟΤΙΚΗ" : "EDITORIAL";
    const sthmana = (Lan === "gr") ? "Στη μάνα, που μου δίδαξε για γλώσσα την ελληνική... \n" : "A mama, que me regaló un idioma...\n";

    const text1 = (Lan==="gr")
        ? "\n\nΤο ΠΕΖΟΔΡΟΜΙΟ είναι μια ανεξάρτητη εκδοτική πλατφόρμα για την κυκλοφορία κειμένων που κρίνουμε ως οχήματα της σύγχρονης σκέψης, καθώς η φωνή τους αντηχεί, μεταμορφώνει και διαρκεί στους αιώνες. Ο θεματικός πυρήνας που σφραγίζει την έναρξη της δραστηριότητάς μας είναι η διαφορετικότητα ως κοινωνικός αντιδραστήρας.\n"
        : "\n\nPEZODROMIO es una plataforma editorial independiente para la circulación de textos que consideramos vehículos del pensamiento contemporáneo, ya que su voz resuena, se transforma y perdura a través de los tiempos. El eje temático que sella el inicio de nuestra actividad es la diversidad como reactor social.\n";
    const text2 = (Lan==="gr") ? "\nΣε μεγάλο βαθμό η πρωτοβουλία μας προκύπτει από την ωρίμανση μιας μακροχρόνιας ανάγκης για την εξοικείωση του ελληνόφωνου αναγνωστικού κοινού με το είδος της εικονογραφημένης νουβέλας, όπου, πέρα από την αφηγηματική και εικαστική της αξία, βρίσκουμε και έναν χώρο διάδοσης ιδεών, στοχασμού και κριτικής, διαλόγου και αντιλόγου, ενώ διακρίνουμε παράλληλα τη μαθησιακή και την εκπαιδευτική της διάσταση.\n"
        : "\nEn gran medida, nuestra iniciativa surge de la maduración de un proyecto generado por la necesidad de familiarizar a los lectores de habla griega con el género de la novela gráfica. A parte del valor narrativo y estético, en este género encontramos también un espacio para la difusión de ideas, para reflexionar y ejercer crítica, hacer diálogo y debate, mientras se pone de manifiesto la dimensión educativa y de aprendizaje.\n";
    const text3 = (Lan==="gr") ? "\nΤο ΠΕΖΟΔΡΟΜΙΟ έχει στόχο να περιλάβει στη βιβλιοθήκη του και άλλα είδη λόγου και έκφρασης, συνεχίζοντας την πορεία του κατ’ αναλογία προς τη στήριξη των αναγνωστών μας.\n"
        : "\nPEZODROMIO pretende incluir en su biblioteca otros géneros literarios y de pensamiento, trazando el rumbo en analogía al apoyo de nuestros lectores.\n";
    const text4 = (Lan==="gr") ? "\nΗ Κυριακή Χριστοφορίδη είναι φιλόλογος και θεατρολόγος, με ερευνητική και διδακτική επάρκεια, μεταφράστρια, ποιήτρια, δραματουργός, δραστηριοποιείται στους τομείς της διαχείρισης πολιτιστικών πόρων, στον προγραμματισμό και την παραγωγή σκηνικών τεχνών και λογοτεχνικών πρότζεκτ, στις διεθνείς σχέσεις και στη διδασκαλία ιστορίας θεάτρου, λογοτεχνίας, ξένων γλωσσών, κ.ά.\n"
        : "\nKyriaki Cristoforidi es filóloga y teatróloga, investigadora y también traductora, poeta, dramaturga, trabaja en la gestión de centros culturales, el diseño y producción de proyectos teatrales y literarios, las relaciones internacionales, la enseñanza de lenguas extranjeras, historia de teatro y literatura, entre otros.\n";
    const text5 = (Lan==="gr") ? "Υπεύθυνη σειράς: Εικονογραφημένη νουβέλα\n" : "Responsable de la colección: Novela gráfica\n";
    const text6 = (Lan==="gr") ? "\nΟ Αντώνης Τσιρικούδης, με σπουδές φιλοσοφίας και κοινωνιολογίας, είναι εκπαιδευτικός με εξειδίκευση σε θέματα ειδικής αγωγής. Γράφει διήγημα και μυθιστόρημα ενώ παράλληλα συμμετέχει στην πρωτοβουλία καλλιτεχνών Lakkos Project.\n"
        : "\nAntonis Tsirikoudis, con estudios en filosofía y sociología, es docente especializado en educación especial, es novelista y al mismo tiempo participa en la iniciativa artística Lakkos Project.\n";

    const text7 = (Lan==="gr") ? "Υπεύθυνος σειράς: ΛΟΑΤΚΙ λογοτεχνίας\n\n\n\n\n\n\n\n\n\n" : "Responsable de la colección: literatura LGBTI\n\n\n\n\n\n\n\n\n";

    const epikoinoniatitle = (Lan==="gr") ? "ΕΠΙΚΟΙΝΩΝΙΑ" : "COMUNICACIÓN\n";
    const epikoinonia1 = (Lan==="gr") ?
        "Κυριακή Χριστοφορίδη,       kyriaki@stopezodromio.com"
        :
        "Kyriaki Cristoforidi,       kyriaki@stopezodromio.com" ;

    const epikoinonia2 = (Lan==="gr") ?
        "Αντώνης Τσιρικούδης,      antonis@stopezodromio.com" :
        "Antonis Tsirikoudis,      antonis@stopezodromio.com";
    const myStyle={
        backgroundImage: mode === "light" ? `url(${patates2})` : `url(${patates3})`  ,
        alignItems: "center",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "center",
        textAlign: "left",
        fontFamily: ["Roboto Condensed","sans-serif"  ].join(","),
        fontSize: "1.3rem",
    };

    return(
        <Box gap="2rem" alignItems="center">

            <Navbar/>


            <WidgetWrapper>
                <FlexBetween>
                    <FlexBetween></FlexBetween>


                        <h4>
                        {introTitle}
                        </h4>


                    <FlexBetween></FlexBetween>

                </FlexBetween>

                <Box
                    width="100%"
                    padding="2rem 6%"
                    display={isNonMobileScreens ? "flex" : "block"}
                    gap = "0.5rem"
                    justifyContent="center"
                    fontSize="30px"
                    textAlign="center"
                >
                    {isNonMobileScreens&&
                    <Typography textAlign="center" maxWidth="65vw" sx={{fontSize:"1.35rem"}}>
                        <FlexBetween></FlexBetween>
                        <span>

                        {intro}

                            </span>
                        <FlexBetween></FlexBetween>
                    </Typography>
                    }
                    {!isNonMobileScreens&&
                        <Typography textAlign="center"  sx={{fontSize:"1.35rem"}}>
                            <FlexBetween></FlexBetween>

                        <span>
                            {intro}
                        </span>


                            <FlexBetween></FlexBetween>
                        </Typography>
                    }

                </Box>
            </WidgetWrapper>
            {isNonMobileScreens && (
            <WidgetWrapper >
                <FlexBetween display="flex" textAlign="center" width="100%" sx={{overflow: "auto"}}>
                    <FlexBetween></FlexBetween>
                    {/*book*/}
                    <FlexBetween>
                    <img alt="book" width="400vw" height="500vh"  src={pig}/>
                    </FlexBetween>
                    <FlexBetween></FlexBetween>

                </FlexBetween>
            </WidgetWrapper>
                )}

            {!isNonMobileScreens && (
                <WidgetWrapper >
                    <FlexBetween display="flex" textAlign="center" width="100%" sx={{overflow: "auto"}}>
                        <FlexBetween></FlexBetween>
                        {/*book*/}
                        <FlexBetween>
                            <img  width="270vw" height="350vh"  src={pig}/>
                        </FlexBetween>
                        <FlexBetween></FlexBetween>

                    </FlexBetween>
                </WidgetWrapper>
            )}

            <WidgetWrapper  id="ekd"  marginBottom="5rem"  alignItems="center" marginTop="10rem" >
                <FlexBetween>
                    <FlexBetween></FlexBetween>


                        <h5>
                        {ektitle}
                        </h5>

                    <FlexBetween></FlexBetween>

                </FlexBetween>
                <FlexBetween >
                    <FlexBetween></FlexBetween>

                    <Typography sx={{fontSize:"1.1rem", fontFamily: "Roboto Condensed"}} >
                        <i>{sthmana}</i>
                    </Typography>
                </FlexBetween>
                <FlexBetween>
                    <FlexBetween  height="100%"></FlexBetween>
                    <FlexBetween  style={myStyle}>

                        <Box
                            width="70vw"
                            alignItems="column"
                            alignContent="column"
                            sx={{textAlign: "left"}}
                        >


                            {/*txt**/}
<Typography textAlign="center">
                            <span>
                                {text1}
                            </span>
                            <span>
                                {text2}
                            </span>
                            <span>
                                {text3}
                            </span>
                            <span>
                                {text4}
                            </span>
                            <span>
                                {text5}
                            </span>
                            <span>
                                {text6}
                            </span>
                            <span>
                                {text7}
                            </span>
</Typography>






                        </Box>
                    </FlexBetween>
                    <FlexBetween  height="100%"></FlexBetween>
                </FlexBetween>
            </WidgetWrapper>
            <div id="ep">

                <Footer/>
            </div>
        </Box>

    )
};

export default HomePage;